import { useEffect, useState } from "react";
import { TableCats } from "../../components/Admin/Category/Table";
import { Modal } from "../../components/Admin/Category/Modal.js";
import { ModalEdit } from "../../components/Admin/Category/ModalEdit.js";
import { ModalDelete } from "../../components/Admin/Category/DeleteModal";
import "../../components/Admin/Category/Table.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import Loading from "./Loading";
import Spinner from "react-bootstrap/Spinner";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/esm/Button";
import { toast } from "react-toastify";
import { BsArrowRepeat, BsPlus } from "react-icons/bs";

export let Category;
export let Index;
export function AdminCategory() {
  const user = useAuth0();
  const [disabled, setdisabled] = useState(false);
  const [isLogIn, setIsLogin] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [roleChanged, setRoleChanged] = useState("Client");
  const [isRoleVerified, setIsRoleVerified] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [isAdmin, setIsAdmin] = useState();
  const [isToken, setIsToken] = useState();
  const { isAuthenticated } = useAuth0();
  const { isLoading, getAccessTokenSilently } = useAuth0();

  async function refreshDependingOnRole() {
    setErrorMsg();
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
        scope: "read:current_user openlid profile name",
      },
    });

    setIsToken(accessToken);

    const user1 = {
      Given_Name: user.user.given_name,
      UserName: user.user.username,
      Email: user.user.email,
      UserId: user.user.Id,
      Role: roleChanged,
    };

    try {
      const res = await axios.post(
        process.env.REACT_APP_AUTH0_API + `api/admin/getallcat`,
        user1,
        {
          headers: {
            Authorization: accessToken,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res.data);
      const cats = res.data;

      setRows(cats);
    } catch (err) {
      console.error(err);
      console.log(err);
      setErrorMsg("Unauthorized");
    }
  }

  async function getImages() {
    try {
      axios
        .get(process.env.REACT_APP_AUTH0_API + `api/images/category`, {})
        .then((res) => {
          console.log(res.data);
          const Prods = res.data;

          setCatImages(Prods);
        });
    } catch {
      toast.error("An error occurred while loading category images");
    } finally {
    }
  }
  async function getall() {
    try {
      refreshDependingOnRole();
      getImages();
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR");
    }
  }
  useEffect(() => {
    if (isAuthenticated) {
      getall();
      refreshDependingOnRole();
    }
  }, [isAuthenticated]);

  function refreshTable() {
    try {
      setdisabled(true);
      getall();
      toast.success("Refreshed!");
    } catch (err) {
      console.log(err);
      toast.error("MAJOR ERROR");
    } finally {
      setTimeout(function () {
        setdisabled(false);
      }, 5000);
    }
  }

  const [catImages, setCatImages] = useState([]);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);

  const handleDeleteRow2 = (e) => {
    Index = e;
    setModalDeleteOpen(true);
  };

  function handleEditRow2(targetIndex) {
    Index = targetIndex;
    setRowToEdit(targetIndex);
    setModalEditOpen(true);
    const toEdit = rows.at(targetIndex);
    const statusState = toEdit.status;
    Category = toEdit;
  }

  const handleSubmit = (newRow) => {
    rowToEdit === null
      ? setRows([...rows, newRow])
      : setRows(
          rows.map((currRow, targetIndex) => {
            if (targetIndex !== rowToEdit) {
              return currRow;
            }

            return newRow;
          })
        );
  };
  return (
    <Container fluid>
      <Row className="justify-content-center">
        {!isLoading && (
          <div>
            <Alert variant="warning" className="alert-display">
              Loading means that an image is loading or doesn't exist
            </Alert>
            <Button
              onClick={() => refreshTable()}
              disabled={disabled}
              variant="outline-success"
            >
              <BsArrowRepeat /> Refresh
            </Button>
            &emsp;
            <Button onClick={() => setModalOpen(true)} variant="success">
              <BsPlus /> Add
            </Button>
            <TableCats
              catImages={catImages}
              rows={rows}
              deleteRow={handleDeleteRow2}
              editRow={handleEditRow2}
            />
            {modalOpen && (
              <Modal
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
              />
            )}
            {modalEditOpen && (
              <ModalEdit
                closeEditModal={() => {
                  setModalEditOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
              />
            )}
            {modalDeleteOpen && (
              <ModalDelete
                closeDeleteModal={() => {
                  setModalDeleteOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
              />
            )}
          </div>
        )}
        {isLoading && (
          <div className="spinner-container">
            <Spinner />
          </div>
        )}
      </Row>
    </Container>
  );
}
export default AdminCategory;
