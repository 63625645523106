import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Stack from "react-bootstrap/Stack";

import "./navBar.css";
import logo from "../../images/Logo-1.jpg";
import AdminLogin from "../../pages/Admin/AdminLogin";
import LogoutButton from "../../pages/Admin/AdminLogOut";
import { useAuth0 } from "@auth0/auth0-react";

import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";

function NavbarFunction() {
  const [isAdminPanel, setAdminPanel] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRoleVerified, setIsRoleVerified] = useState(false);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const user = useAuth0();

  console.log(process.env.REACT_APP_AUTH0_DOMAIN);
  console.log(process.env.REACT_APP_AUTH0_CLIENTID);
  console.log(process.env.REACT_APP_AUTH0_CALLBACK_URL);

  if (isAuthenticated) {
    validateRole();

    console.log(user?.user?.role);
  } else {
    console.log("notlogin");
  }

  async function validateRole() {
    if (!isRoleVerified) {
      if (user?.user?.role.includes("Admin")) {
        setIsAdmin(true);
        setIsRoleVerified(true);
        setAdminPanel(false);
      }
      if (user?.user?.role.length == 1) {
        if (user?.user?.role.includes("Client")) {
          setIsAdmin(false);

          setIsRoleVerified(true);
          setAdminPanel(false);
        }
      }
      if (user?.user?.role.includes("Manager")) {
        setIsAdmin(true);
        setIsRoleVerified(true);
        setAdminPanel(false);
      }
    }
  }

  return (
    !isAdminPanel && (
      <>
        {/* <Profile/> */}
        <Stack
          style={{
            height: "100%",
          }}
        >
          <Navbar expand="lg" className="bg-body-tertiary mb-4">
            <Container fluid>
              <Navbar.Brand href="/">
                <img alt="" src={logo} className="d-inline-block align-top" />{" "}
              </Navbar.Brand>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-xl`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-xl`}
                aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-xl`}>
                    ZD Motor Canada
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-center flex-grow-1 pe-3">
                    <Nav.Link href="/">Home</Nav.Link>
                    <Nav.Link href="/aboutus">About us</Nav.Link>
                    <Nav.Link href="/products">Products</Nav.Link>
                    <Nav.Link href="/certification">Certification</Nav.Link>
                    <Nav.Link href="/contact">Contact us</Nav.Link>
                    {!isAuthenticated && (
                      <div className="Login">
                        <AdminLogin />
                      </div>
                    )}
                    {isAuthenticated && (
                      <div className="loggedin">
                        <div className="dropdown">
                          <Dropdown className="dropdownreal">
                            <Dropdown.Toggle
                              variant="Secondary"
                              id="dropdown-basic"
                              className="dropdowntoggle"
                            >
                              <div className="username">
                                {" "}
                                {user.user.username} {user.user.given_name}
                              </div>
                              <img
                                src={user.user.picture}
                                className="imageprofil"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdownmenu">
                              {isAdmin && (
                                <Dropdown.Item
                                  href="/admin"
                                  onClick={() => {
                                    setAdminPanel(true);
                                  }}
                                >
                                  Management
                                </Dropdown.Item>
                              )}
                              <NavDropdown.Divider />
                              <div className="logout">
                                <LogoutButton />
                              </div>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    )}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </Stack>
      </>
    )
  );
}

export default NavbarFunction;
