import { useEffect, useState } from "react";
import { BsFillTrashFill, BsFillPencilFill } from "react-icons/bs";
import "./Table.css";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

export const TableCats = ({ catImages, rows, deleteRow, editRow }) => {
  const [cat, setcat] = useState();
  useEffect(() => {
    setcat(rows);
  });
  return (
    <Table striped bordered hover responsive variant="dark">
      <thead>
        <tr>
          <th>Image</th>
          <th>Id</th>
          <th>Nom</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {cat?.map((row, idx) => {
          const img = catImages.find(
            (i) => i.ObjectName.split(".")[0] == row.categoryId
          );
          const statusState = row.status;
          function loadingIconStyle() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "spinner-Style";
            } else {
              return "";
            }
          }

          function imageAvailable() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return;
            } else {
              return (
                process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName
              );
            }
          }

          function animation() {
            if (
              process.env.REACT_APP_AUTH0_IMAGES +
                "categories/" +
                img?.ObjectName ==
              process.env.REACT_APP_AUTH0_IMAGES + "categories/undefined"
            ) {
              return "border";
            } else {
              return "";
            }
          }
          function statusTexted() {
            if (statusState === true) {
              return "Actif";
            } else {
              return "Inactif";
            }
          }

          return (
            <tr key={idx}>
              <td>
                <div className={loadingIconStyle()}>
                  <Spinner animation={animation()} size="sm" variant="info" />
                </div>
                <Image
                  style={{
                    maxHeight: "18rem",
                  }}
                  src={imageAvailable()}
                />
              </td>
              <td>{row.categoryId}</td>
              <td>{row.name}</td>
              <td>
                <span className={`label label-${row.status}`}>
                  {statusTexted()}
                </span>
              </td>
              <td>
                <span className="actions">
                  <BsFillTrashFill
                    className="delete-btn"
                    onClick={() => {
                      deleteRow(row);
                    }}
                  />
                  <BsFillPencilFill
                    className="edit-btn"
                    onClick={() => {
                      editRow(idx);
                    }}
                  />
                </span>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
